



























































































































import Vue from "vue";
import moment from "moment";
import apiClient from "@/services/apiClient";

export default Vue.extend({
  data() {
    return {
      items: [],
      totalItem: null,
      currentDate: "",
      loading: false,
      headers: [
        { text: "Số xe", value: "id" },
        { text: "Loại xe", value: "type" },
        { text: "Ngày về VN", value: "vn_time" },
        { text: "Chi phí xuất khẩu", value: "export_fee_export" },
        { text: "Chi phí nhập khẩu", value: "export_fee_import" },
        { text: "Chi phí Bích Thủy", value: "export_fee_transport" },
        { text: "Chi phí VN", value: "export_fee_unload_vn" },
        { text: "Chi phí khác", value: "export_fee_extra" },
        { text: "Tổng chi phí", value: "export_fee_total" },
        { text: "Tổng tiền vận chuyển", value: "total_fee_transport" },
        { text: "Tổng tiền ủy thác", value: "total_fee_trust" },
        { text: "Tổng LNTT", value: "total_profit" },
        { text: "Báo dư", value: "total_surplusage" },
        { text: "Lợi nhuận", value: "profit" },
      ],
      availableTypeMap: {
        tap_13m: "Tạp 13m",
        tap_9m6: "Tạp 9m6",
        tap_17m: "Tạp 17m",
        tap_cont: "Cont tạp",
        tap_cont_20: "Cont tạp 20",
        tap_cont_40: "Cont tạp 40",
        tx: "Tách xe",
        nx: "Nguyên xe",
        nx_13m: "Nguyên xe 13m",
        nx_17m: "Nguyên xe 17m",
        nx_air: "Air",
        nx_cb: "Nguyên cont",
        nx_l: "Nguyên cont lẻ",
        db_cont_20: "Cont 20 đặc biệt",
        db_cont_40: "Cont 40 đặc biệt",
      },
      timeModes: [
        {
          value: "month",
          text: "Tháng",
        },
        {
          value: "year",
          text: "Năm",
        },
      ],
      timeMode: "month",
      currentStatus: "dtl",
      availableStatuses: [
        { value: "dtl", text: "Đã thanh lý" },
        { value: "hdv", text: "Hàng đã về" },
      ],
    };
  },
  watch: {
    currentDate() {
      this.initialize();
    },
    timeMode() {
      this.initialize();
    },
    currentStatus() {
      this.initialize();
    },
  },
  methods: {
    async initialize() {
      if (!this.currentDate) {
        return;
      }
      const m = moment(this.currentDate);
      const startTime = m.startOf(this.timeMode).unix();
      const endTime = m.endOf(this.timeMode).unix();
      const options: any = {
        extra: {
          statistics: true,
          status: this.currentStatus,
        },
        filters: [
          {
            key: "vn_time",
            operator: ">=",
            value: startTime,
          },
          {
            key: "vn_time",
            operator: "<=",
            value: endTime,
          },
        ],
      };
      switch (this.currentStatus) {
        case "dtl":
          options.extra.liquidation_slip_approve_time = {
            from: startTime,
            to: endTime,
          };
          break;
        case "hdv":
          options.extra.truck_vn_time = {
            from: startTime,
            to: endTime,
          };
          break;
      }
      this.loading = true;
      let { items } = await apiClient.truckGetAll(options);
      items = items.map((item) => {
        item.profit =
          item.total_fee_transport +
          item.total_fee_trust +
          item.total_profit +
          item.total_surplusage -
          item.export_fee_total;
        return item;
      });

      this.totalItem = items.reduce(
        (totalItem, item) => {
          totalItem.export_fee_export += item.export_fee_export;
          totalItem.export_fee_import += item.export_fee_import;
          totalItem.export_fee_transport += item.export_fee_transport;
          totalItem.export_fee_unload_vn += item.export_fee_unload_vn;
          totalItem.export_fee_extra += item.export_fee_extra;
          totalItem.export_fee_total += item.export_fee_total;
          totalItem.total_fee_transport += item.total_fee_transport;
          totalItem.total_fee_trust += item.total_fee_trust;
          totalItem.total_profit += item.total_profit;
          totalItem.total_surplusage += item.total_surplusage;
          totalItem.profit += item.profit;
          return totalItem;
        },
        {
          export_fee_export: 0,
          export_fee_import: 0,
          export_fee_transport: 0,
          export_fee_unload_vn: 0,
          export_fee_extra: 0,
          export_fee_total: 0,
          total_fee_transport: 0,
          total_fee_trust: 0,
          total_profit: 0,
          total_surplusage: 0,
          profit: 0,
        }
      );

      this.items = items;
      this.loading = false;
    },
  },
  async created() {
    this.currentDate = moment().format("YYYY-MM");
  },
});
